import GetImage from 'components/functional/getImage'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { media } from 'utils/media'
import CloseIcon from 'svgs/single-cross.svg'
import YellowX from 'svgs/yellowx.svg'
import Stripes from 'svgs/stripes.svg'
import { MaxWidth } from 'components/global'

const AmbassadorsBodyStyles = styled.section`
  width: 100%;
  background-color: var(--white);
  section {
    z-index: 5;
    padding: 1rem 0 3.5rem 0;
    color: var(--darkGrey);
    max-width: var(--maxWidth);
    .wrapper {
      margin-top: 2rem;
      text-align: center;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 2rem;
      @media ${media.sm} {
        max-width: 400px;
        margin: 2rem auto;
      }
      @media ${media.md} {
        grid-template-columns: repeat(1, 1fr);
        max-width: 400px;
        margin-top: 0;
      }
      @media ${media.lg} {
        grid-template-columns: repeat(1, 1fr);
        max-width: 400px;
      }
      .ambassador {
        position: relative;
        z-index: 10;
        .desktop {
          display: none;
          @media ${media.sm} {
            display: block;
          }
        }
        .mobile {
          @media ${media.sm} {
            display: none;
          }
        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          background-color: var(--purpleO);
          transition: var(--transition);
          backdrop-filter: blur(1px);
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover,
          &active {
            opacity: 1;
          }

          button {
            &:hover {
              background-color: var(--darkGrey);
            }
          }
        }
        &:nth-child(2) {
          .overlay {
            background-color: var(--blueO);
          }
        }
      }
    }
    @media ${media.md} {
      padding: 0;
    }
  }
  .stripes {
    position: relative;
    width: 100%;
    height: 5rem;
    overflow-y: visible;
    margin-top: 3rem;
    @media ${media.sm} {
      margin-top: 5rem;
    }
    @media ${media.lg} {
      margin-top: 1rem;
    }
    #stripes {
      position: absolute;
      z-index: 0;
      bottom: 0rem;
      left: -25rem;
      width: 35rem;
      @media ${media.ls} {
        width: 35rem;
        left: -20rem;
      }
      @media ${media.sm} {
        width: 35rem;
        left: -25rem;
      }
      @media ${media.lg} {
        width: 45rem;
        left: -31rem;
      }
      @media ${media.xl} {
        width: 50rem;
        left: -31rem;
      }
    }
  }
  .cross {
    position: relative;
    width: 100%;
    height: 5rem;
    overflow-y: visible;
    display: none;
    @media ${media.sm} {
      display: block;
    }
    #yellowX {
      position: absolute;
      top: 1rem;
      right: -5rem;
      //     width: 12rem;
      @media ${media.ls} {
        right: -2rem;
      }
      @media ${media.sm} {
        width: 13rem;
        right: 0rem;
        top: 0.5rem;
      }
      @media ${media.lg} {
        right: 2rem;
        top: 2rem;
        width: 14rem;
      }
      @media ${media.xl} {
        right: 6rem;
      }
    }
  }
`
const AmbassadorsBody = ({ data }) => (
  <AmbassadorsBodyStyles>
    <div className="cross">
      <YellowX id="yellowX" />
    </div>
    <MaxWidth>
      <section>
        <div className="wrapper">
          {data.map((data, i) => (
            <SingleAmbassador data={data} key={i} />
          ))}
        </div>
      </section>
    </MaxWidth>
    <div className="stripes">
      <Stripes id="stripes" />
    </div>
  </AmbassadorsBodyStyles>
)

export default AmbassadorsBody

const SingleAmbassador = ({ data }) => {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    }
    if (!open) {
      document.body.style.overflow = 'auto'
    }
  }, [open])
  return (
    <>
      {open ? <BioPopUp data={data} open={open} setOpen={setOpen} /> : ''}
      <div className="ambassador" onClick={() => setOpen(true)}>
        <div className="mobile">
          <GetImage data={data.mobileImage.src} />
        </div>
        <div className="desktop">
          <GetImage data={data.desktopImage.src} />
        </div>
        <div className="overlay">
          <button type="button" className="btn btn-main">
            <span>Read more</span>
          </button>
        </div>
      </div>
    </>
  )
}

const PopUpStyles = styled.div`
  animation: fade-in 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  .popup {
    animation: slide-in-bottom 0.5s 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    max-width: var(--maxWidth);
    overflow: scroll;
    position: relative;
    background-color: var(--offWhite);
    border-radius: 7px;
    padding: 2rem 1.5rem;
    width: 90%;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    h5 {
      font-size: 2.5rem;
      margin-top: 0.5rem;
    }
    h6 {
      margin-top: 0.25rem;
      font-size: 1.5rem;
    }
    p {
      margin-top: 1rem;
    }
    .img {
      width: 80%;
      height: auto;
      margin: var(--auto);
      text-align: center;
      .gatsby-image-wrapper {
        width: 100%;
        height: auto;
        margin: var(--auto);
      }
      @media ${media.md} {
        width: 85%;
      }
    }
    @media only screen and (min-width: 375px) {
      width: 85%;
    }
    @media ${media.md} {
      display: flex;
      flex-direction: row;
      padding: 3rem;
      justify-content: center;
      align-items: baseline;
      .img {
        flex: 0 0 40%;
        max-width: 40%;
        position: sticky;
        top: 0;
      }
      aside {
        flex: 0 0 50%;
        max-width: 50%;
        top: 0rem;
        p {
          text-align: left;
        }
      }
    }
    @media ${media.xl} {
      width: 70%;
      aside {
        div {
          padding-bottom: 2rem;
        }
      }
    }
  }
  .close {
    position: absolute;
    top: 1.25rem;
    right: 1rem;
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
`
const BioPopUp = ({ data, open, setOpen }) => {
  const i = true
  return (
    <PopUpStyles onClick={() => setOpen(false)}>
      <section className="popup">
        <div className="img">
          <GetImage data={data.mobileImage.src} alt={data.mobileImage.alt} />
        </div>
        <aside>
          <div dangerouslySetInnerHTML={{ __html: data.bio }} />
        </aside>
        <CloseIcon onClick={() => setOpen(false)} className="close" />
      </section>
    </PopUpStyles>
  )
}

// .branding {
//   position: absolute;
//   top: 5rem;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   z-index: 0;
//   section {
//     position: relative;
//     width: 100%;
//     height: 100%;
//   }
//

// }
