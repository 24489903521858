import React from 'react'
import { graphql } from 'gatsby'

import AmbassadorsBody from 'components/ambassadors/body'

import { Layout, SubHeader } from 'components/global'

const Ambassadors = ({ data }) => {
  const metaData = {
    title: 'Our Ambassadors | Arnold Clark Cup',
  }
  const ambassadors = data.ambassadors.nodes
  return (
    <Layout title={metaData.title} $noAnimation>
      <SubHeader title="Our Legends" />
      <AmbassadorsBody data={ambassadors} />
    </Layout>
  )
}

export default Ambassadors

export const AmbassadorQuery = graphql`
  query {
    ambassadors: allAmbassadorsJson {
      nodes {
        name
        bio
        desktopImage {
          alt
          src {
            childImageSharp {
              gatsbyImageData(
                width: 1000
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        mobileImage {
          alt
          src {
            childImageSharp {
              gatsbyImageData(
                width: 1000
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
